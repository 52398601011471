import React from 'react'
import { Alert } from 'react-bootstrap'

export const Errors: React.FC<{ errors: string[] }> = ({ errors }) => {
  return (
    <Alert className='rounded-0' variant='danger' style={{ fontSize: 14 }}>
      <p className='mb-0' style={{ fontWeight: 'bold' }}>Se han producido errores:</p>
      <ul className='my-0' style={{ listStyleType: 'decimal' }} >

        {errors.map((error, index) => (
          <li key={index}>{error}</li>
        ))}
      </ul>
    </Alert>
  )
}